import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// page routing
const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));
const Bookings = Loadable(lazy(() => import('views/pages/bookings')));
const Customers = Loadable(lazy(() => import('views/pages/customers')));
const Services = Loadable(lazy(() => import('views/pages/services')));
const PackageDeals = Loadable(lazy(() => import('views/pages/package-deals')));
const Staff = Loadable(lazy(() => import('views/pages/staff')));
const RecentAdded = Loadable(lazy(() => import('views/pages/recent-added')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/bookings',
      element: <Bookings />,
    },
    {
      path: '/customers',
      element: <Customers />
    },
    {
      path: '/services',
      element: <Services />
    },
    {
      path: '/package-deals',
      element: <PackageDeals />
    },
    {
      path: '/staff',
      element: <Staff />
    },
    {
      path: '/recent-added',
      element: <RecentAdded />
    },
  ]
};

export default MainRoutes;
